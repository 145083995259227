import Image from 'next/image';
import { HempitectureLogo } from '@/components/shared/Logos';
import { cn } from '@/utils/helper';
import hempcrete from '@/public/hempcrete-block.webp';
import hempwool from '@/public/HempWool-Stacked.jpg';
import styles from './CollectionPicker.module.scss';
import { CountdownBanner } from '@/components/alerts/CountdownBanner';

const buttons = [
  {
    title: 'Shop Hempcrete',
    alt: 'Shop Hempcrete',
    value: 'hempcrete',
    className: styles.hempcrete,
    image: hempcrete,
  },
  {
    title: <>Shop HempWool Batt & Carpet Underlayment</>,
    alt: 'Shop HempWool',
    value: 'hempwool',
    className: styles.hempwool,
    image: hempwool,
  },
];

interface CollectionPickerProps {
  setProductType: (type: 'hempcrete' | 'hempwool') => void;
}

export const CollectionPicker = ({ setProductType }: CollectionPickerProps) => {
  return (
    <div className={cn(styles.root, 'flex flex-col fixed cover')}>
      <CountdownBanner
        targetDate="2024-12-02T15:59:00-08:00"
        leftText="Black Friday Sale - 9.99% OFF"
        rightText="Now through December 1"
      />
      <div>
        <div className={styles.heading}>
          <HempitectureLogo style={{ filter: 'invert(1)', height: 80 }} />
          <h1>Buy Direct</h1>
        </div>
        <div>
          {buttons.map(({ title, value, className, image, alt }) => (
            <button
              className={cn(styles.button, className)}
              key={value}
              onClick={() => setProductType(value as 'hempwool' | 'hempcrete')}>
              <div className={styles.image_container}>
                <Image
                  src={image}
                  alt={alt}
                  priority
                  placeholder="blur"
                  layout="fill"
                  objectFit="cover"
                />
              </div>
              <div className={styles.label}>{title}</div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
